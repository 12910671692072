import { isArray } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { arrayEquals, cleanTextValue, flatMap, isPromise } from 'ytil'
import { AutoCompleteFieldProps, AutoCompleteSection, isAutoCompleteSection } from './types'

export interface Options {
  allowCreate?:              boolean
  preventDuplicates?:        boolean
  searchEnabled?:            boolean
  minimumSearchQueryLength?: number
  throttle?:                 number | null
}

type Callbacks =
  | 'onSearch'
  | 'onChange'
  | 'onSelect'
  | 'onClear'
  | 'requestCreate'
  | 'valueForChoice'

export default class StateManager<V extends Primitive, C> {

  constructor(
    private readonly multi: boolean,
    private readonly options: Options,
  ) {
    makeObservable(this)
  }

  //------
  // Value

  @observable
  public values: V[] = []

  @action
  public setValue(value: V | V[] | null) {
    const values = isArray(value) ? value : value != null ? [value] : []
    if (arrayEquals(this.values, values)) { return }

    this.values = values
  }

  //------
  // Callbacks

  private callbacks: Partial<Pick<AutoCompleteFieldProps<V, C>, Callbacks>> = {}

  public setCallbacks(callbacks: Partial<Pick<AutoCompleteFieldProps<V, C>, Callbacks>>) {
    Object.assign(this.callbacks, callbacks)
  }

  //------
  // Other properties

  @observable
  public query: string | null = null

  @observable
  public searching: boolean = false

  @observable
  private propsResults: C[] | AutoCompleteSection<C>[] | null = null

  @observable
  private searchResults: C[] | AutoCompleteSection<C>[] | null = null

  @action
  public setResults(value: C[] | AutoCompleteSection<C>[] | null) {
    if (value == null) { return }
    if (arrayEquals(this.propsResults ?? [] as any[], value ?? [] as any[])) { return }
    this.propsResults = value
  }

  @computed
  public get choices(): C[] {
    const results = this.propsResults ?? this.searchResults ?? []
    if (results.length === 0) { return [] }
    if (isAutoCompleteSection(results[0])) {
      return flatMap(this.sections ?? [], section => section.choices)
    } else {
      return results as C[]
    }
  }

  @computed
  public get sections(): AutoCompleteSection<C>[] | null {
    const results = this.propsResults ?? this.searchResults ?? []
    if (results.length === 0) { return null }
    if (!isAutoCompleteSection(results[0])) { return null }

    return results as AutoCompleteSection<C>[]
  }

  @observable
  public selectedIndex: number | null = null

  //------
  // Searching

  private lastSearchQuery: string | null = null

  @action
  public setSearchQuery(query: string | null) {
    if (query === this.query) { return }

    this.query = query
    this.performSearch(query)
  }

  @action
  public performSearch(query: string | null = this.query, force: boolean = true) {
    const {searchEnabled, minimumSearchQueryLength} = this.options
    if (!searchEnabled) { return }

    query = cleanTextValue(query, true)

    if (query != null && minimumSearchQueryLength != null && query.length < minimumSearchQueryLength) {
      query = null
    }

    if (!force && this.choices.length > 0 && query === this.lastSearchQuery) {
      return
    }
    this.lastSearchQuery = query
    this.searching = true

    const promiseOrValue = this.callbacks.onSearch?.(query)
    if (isPromise(promiseOrValue)) {
      return promiseOrValue.then(choices => this.onSearchComplete(query, choices == null ? null : choices))
    } else if (promiseOrValue != null) {
      this.onSearchComplete(query, promiseOrValue)
    } else {
      this.searching = false
    }
  }

  @action
  private onSearchComplete(query: string | null, results: null | C[] | AutoCompleteSection<C>[]) {
    if (query !== this.lastSearchQuery) { return }

    if (results != null) {
      this.searchResults = results
    }

    this.searching     = false
    this.selectedIndex = null
  }

  //------
  // Choice selection

  @action
  public selectChoice(choice: C) {
    const value = this.valueForChoice(choice)

    if (this.multi) {
      (this.callbacks.onChange as any)?.([...this.values, value])
    } else {
      (this.callbacks.onChange as any)?.(value)
    }

    const index = this.choices.indexOf(choice)
    if (index >= 0) { this.selectedIndex = index }

    this.query = null
    this.selectListeners.forEach(l => l())
    this.callbacks.onSelect?.(choice)
  }

  @action
  public remove(...valuesToRemove: V[]) {
    if (!this.multi) { return }

    const nextValues = this.values.filter(value => !valuesToRemove.includes(value))
    ;(this.callbacks.onChange as any)?.(nextValues)
  }

  @action
  public clear() {
    if (this.multi) {
      (this.callbacks.onChange as any)?.([])
    } else {
      (this.callbacks.onChange as any)?.(null)
    }
    this.query = null
    this.selectedIndex = null
    this.searchResults = []
    this.performSearch(this.query, true)
    this.callbacks.onClear?.()
  }

  //------
  // Creation

  @computed
  public get allowCreate() {
    if (this.callbacks.requestCreate == null) { return false }
    if (this.query == null) { return false }

    return this.query.trim().length > 0
  }

  public async createNew() {
    const name   = this.query?.trim() ?? ''
    const choice = await this.callbacks.requestCreate?.(name)
    if (choice == null) { return }

    this.selectChoice(choice)
  }

  //------
  // Keyboard selection

  @computed
  private get availableChoiceCount() {
    return this.choices.length + (this.allowCreate ? 1 : 0)
  }

  @action
  public moveDown() {
    if (this.availableChoiceCount === 0) { return }

    let index: number
    if (this.selectedIndex == null) {
      index = 0
    } else {
      index = this.selectedIndex + 1
    }
    if (index >= this.availableChoiceCount) {
      index = 0
    }

    this.selectedIndex = index
  }

  @action
  public moveUp() {
    if (this.availableChoiceCount === 0) { return }

    let index: number
    if (this.selectedIndex == null) {
      index = this.availableChoiceCount - 1
    } else {
      index = this.selectedIndex - 1
    }
    if (index < 0) {
      index = this.availableChoiceCount - 1
    }

    this.selectedIndex = index
  }

  public commit() {
    if (this.selectedIndex != null && this.selectedIndex < this.choices.length) {
      const choice = this.choices[this.selectedIndex]
      this.selectChoice(choice)
    } else if (this.allowCreate) {
      this.createNew()
    }
  }

  //------
  // Conversions

  private valueForChoice(choice: C) {
    if (this.callbacks.valueForChoice != null) {
      return this.callbacks.valueForChoice(choice)
    } else {
      return choice as any as V
    }
  }

  //------
  // Selection listeners

  private selectListeners = new Set<() => any>()

  public addSelectListener(listener: () => any) {
    this.selectListeners.add(listener)
    return () => {
      this.selectListeners.delete(listener)
    }
  }

}