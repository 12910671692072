import { isEmpty, mapValues } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { CustomImage, Link, LocalizedString, MenuPageItem, Page } from '~/models'
import { FormModel, SubmitResult } from '~/ui/form'
import PagesContext from '../PagesContext'
import { NewItem } from './MenuPageStore'

export default class MenuPageItemFormModel implements FormModel {

  constructor(
    private readonly item: MenuPageItem | NewItem,
    private readonly index: number,
    private readonly page: Page,
    private readonly context: PagesContext,
  ) {
    this.image   = item === NewItem ? null : item.image
    this.caption = item === NewItem ? {} : item.caption
    this.detail  = item === NewItem ? {} : item.detail
    this.link    = item === NewItem ? Link.empty() : item.link

    makeObservable(this)
  }

  @observable
  public image: CustomImage | null

  @observable
  public caption: LocalizedString

  @observable
  public detail: LocalizedString | null

  @observable
  public link: Link

  public async submit(): Promise<SubmitResult | undefined> {
    const items = this.item === NewItem ? (
      [...this.page.items, this.buildItem()]
    ) : (
      this.page.items.map((item, index) => {
        if (index === this.index) {
          return this.buildItem()
        } else {
          return item
        }
      })
    )

    return await this.context.updatePage(this.page.id, {items})
  }

  protected buildItem() {
    return {
      caption: mapValues(this.caption, it => it.trim()),
      // TO-DO: fix this properly. A localized string should never contain null values.
      detail:  isEmpty(this.detail) ? null : mapValues(this.detail, it => cleanTextValue(it, true)),
      image:   this.image,
      link:    this.link,
    }
  }

}