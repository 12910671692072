import React from 'react'
import { useTranslation } from 'react-i18next'
import { isFunction, isPlainObject } from 'lodash'
import { cleanTextValue } from 'ytil'
import { observer } from '~/ui/component'
import {
  Center,
  Chip,
  ClearButton,
  Empty,
  HBox,
  Label,
  ListItem,
  Panel,
  Popup,
  Scroller,
  Spinner,
  Tappable,
  VBox,
} from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { usePrevious } from '~/ui/hooks'
import {
  colors,
  createUseStyles,
  layout,
  presets,
  ThemeProvider,
  useStyling,
  useTheme,
} from '~/ui/styling'
import { isReactText } from '~/ui/util'
import StateManager from './StateManager'
import { AutoCompleteFieldProps } from './types'

const _AutoCompleteField = <V extends Primitive, C = V>(props: AutoCompleteFieldProps<V, C>) => {

  const $     = useStyles()

  const theme = useTheme()
  const [t]   = useTranslation('autocomplete_field')

  const {
    multi = false,
    value,
    onChange,
    onSearch,
    results,
    onSelect,
    onClear,
    requestCreate,
    valueForChoice,
    iconForChoice,
    captionForChoice,
    detailForChoice,
    choiceForValue,
    invalid,
    readOnly = false,
    enabled = true,
    searchEnabled = true,
    minimumSearchQueryLength = 0,
    onEndReached,
    throttle = 200,
    inputStyle = 'normal',
    smallChips = false,
    searchInputClassNames,
  } = props

  // Use a dedicated state manager for this component.
  const stateManager = React.useMemo(
    () => new StateManager<V, C>(multi, {
      searchEnabled,
      minimumSearchQueryLength,
      throttle,
    }),
    [multi, searchEnabled, minimumSearchQueryLength, throttle],
  )

  // Continuously update the state manager's value.
  stateManager.setValue(value)
  stateManager.setResults(results ?? null)
  stateManager.setCallbacks({
    onSearch,
    onChange,
    onSelect,
    onClear,
    requestCreate,
    valueForChoice,
  })

  const {colors} = useStyling()
  const [open, setOpen] = React.useState<boolean>(false)
  const searchInputRef  = React.useRef<HTMLInputElement>(null)

  const values        = stateManager.values
  const query         = stateManager.query
  const searching     = stateManager.searching
  const choices       = stateManager.choices
  const sections      = stateManager.sections
  const selectedIndex = stateManager.selectedIndex

  const minValues      = ((props as any).minValues ?? 0) as number
  const maxValues      = ((props as any).maxValues ?? null) as number | null

  const renderChoiceChip = ((props as any).renderChoiceChip) as ((choice: C) => React.ReactNode) | undefined

  const hasSingleValue = !multi && values.length > 0
  const empty          = values.length === 0
  const mayAddNew      = !readOnly && enabled && (multi || values.length === 0)
  const mayRemove      = !readOnly && enabled && values.length > minValues
  const mayAddMore     = multi && (maxValues == null ? true : values.length < maxValues)

  const keyForValue = React.useCallback((value: V) => {
    return value.toString()
  }, [])

  //------
  // Rendering

  function render() {
    const {classNames, small} = props

    return (
      <HBox classNames={[$.AutoCompleteField, {invalid, small, empty, hasSingleValue, readOnly, disabled: !enabled}, inputStyle, classNames]} align='stretch'>
        <VBox flex>
          {renderValue()}
          {!multi && renderSearch()}
        </VBox>
        {mayAddNew && (
          <Center>
            <SVG
              classNames={$.dropDownArrow}
              name='chevron-down'
              size={layout.icon.s}
              dim
            />
          </Center>
        )}
      </HBox>
    )
  }

  function renderValue() {
    if (multi) {
      return renderValues(values)
    } else if (values.length > 0) {
      return renderSingleValue(values[0])
    } else if (readOnly) {
      return renderEmpty()
    } else {
      return null
    }
  }

  function renderValues(values: V[]) {
    if (choiceForValue == null) { return null }

    const choices = values.map(choiceForValue)
    const requestRemove = (index: number) => mayRemove ? () => stateManager.remove(values[index]) : undefined

    return (
      <HBox flex='grow' classNames={$.multiValues}>
        {choices.map((choice, index) => choice == null ? null : (
          <VBox key={keyForValue(values[index])} justify='middle' classNames={$.multiValue}>
            <Chip
              children={renderChoiceChip?.(choice) ?? captionForChoice?.(choice) ?? choice as any}
              requestRemove={requestRemove(index)}
              small={smallChips}
            />
          </VBox>
        ))}
        {mayAddMore && (
          <VBox classNames={[$.multiSearch, {small: smallChips}]}>
            {renderSearch()}
          </VBox>
        )}
      </HBox>
    )
  }

  function renderSingleValue(value: V) {
    const choice = choiceForValue?.(value) ?? value as unknown as C
    if (choice == null) { return null }

    const icon    = iconForChoice?.(choice)
    const caption = captionForChoice?.(choice) ?? choice as any as string
    const detail  = detailForChoice?.(choice)
    const depth   = inputStyle === 'normal' ? 1 : 0

    return (
      <Panel
        flex
        semi={false}
        bandColor={colors.semantic.secondary}
        depth={depth}
        classNames={[$.singleValue, inputStyle]}
        contentClassNames={$.singleValueContent}
      >
        <HBox flex gap={layout.padding.inline.m}>
          {typeof icon === 'string' ? (
            <SVG name={icon as SVGName} size={layout.icon.m}/>
          ) : icon}
          <VBox flex>
            <Label bold truncate>
              {caption}
            </Label>
            {isReactText(detail) ? (
              <Label tiny dim markup>
                {detail}
              </Label>
            ) : detail}
          </VBox>
          {mayRemove && (
            <ClearButton
              icon='cross'
              onTap={clear}
              round
            />
          )}
        </HBox>
      </Panel>
    )
  }

  function renderEmpty() {
    const emptyPlaceholder = props.emptyPlaceholder ?? props.placeholder
    if (emptyPlaceholder == null) { return null }

    return (
      <HBox flex='grow' classNames={$.emptyPlaceholder}>
        <Label dim>
          {emptyPlaceholder}
        </Label>
      </HBox>
    )
  }

  function renderSearch() {
    if (!mayAddNew) { return null }

    return (
      <Popup
        open={open}
        requestClose={requestClose}
        renderContent={renderResults}
        targetClassNames={$.search}
        classNames={$.popup}
        matchTargetSize={true}
        crossAlign='center'
        gap={0}
        minSize={searching && empty ? undefined : minResultsHeight}
        screenPadding={0}
        children={renderSearchInput()}
      />
    )
  }

  function renderSearchInput() {
    return (
      <input
        ref={searchInputRef}
        type='text'
        classNames={[$.searchInput, searchInputClassNames]}

        value={query ?? ''}
        onChange={onSearchChange}
        placeholder={props.placeholder ?? undefined}

        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        onClick={handleSearchClick}
        onKeyDown={onKeyDown}

        disabled={!enabled}
      />
    )
  }

  function renderResults() {
    const empty         = choices.length === 0 && requestCreate == null
    const showSearching = searching && empty

    return (
      <VBox flex='both' onMouseDown={handleResultsMouseDown}>
        <Scroller flex='both' onEndReached={onEndReached} shadows={false}>
          {!showSearching && renderCreateChoice()}
          {showSearching ? (
            <Center padding={layout.padding.s}>
              <Spinner color={theme.fg.dim} size={12}/>
            </Center>
          ) : empty ? (
            renderNoResultsPlaceholder()
          ) : (
            renderChoices()
          )}
        </Scroller>
      </VBox>
    )
  }

  function renderChoices() {
    if (sections != null) {
      return sections.map((section, index) => (
        <VBox classNames={$.section} key={index}>
          {section.caption != null && (
            <VBox classNames={$.sectionHeader}>
              <Label caption small dim>
                {section.caption}
              </Label>
              {section.loading && (
                <Center>
                  <Spinner/>
                </Center>
              )}
            </VBox>
          )}
          <VBox>
            {section.choices.map(renderChoiceButton)}
          </VBox>
        </VBox>
      ))
    } else {
      return choices.map(renderChoiceButton)
    }

  }

  function renderNoResultsPlaceholder() {
    const noResultsPlaceholder = props.noResultsPlaceholder ?? defaultNoResultsPlaceholder()

    return (
      <VBox flex='grow' justify='middle' classNames={$.noResultsPlaceholder}>
        {isPlainObject(noResultsPlaceholder) ? (
          <Empty
            {...noResultsPlaceholder}
            padded={false}
            gap={layout.padding.inline.m}
            markup
          />
        ) : (
          <Label dim italic align='center'>
            {noResultsPlaceholder}
          </Label>
        )}
      </VBox>
    )
  }

  function renderChoiceButton(choice: C, index: number) {
    const value    = valueForChoice?.(choice) ?? choice as any as V
    const selected = index === selectedIndex

    return (
      <Tappable
        key={keyForValue(value)}
        classNames={[$.choiceButton, {selected}]}
        onTap={selectChoice.bind(null, choice)}
      >
        {renderChoice(choice)}
      </Tappable>
    )
  }

  function renderChoice(choice: C) {
    if (props.renderChoice != null) {
      return props.renderChoice(choice)
    } else {
      return renderDefaultChoice(choice)
    }
  }

  function renderDefaultChoice(choice: C) {
    const icon    = iconForChoice?.(choice)
    const caption = captionForChoice?.(choice) ?? choice as any
    const detail  = detailForChoice?.(choice)

    return (
      <ListItem
        image={icon}
        caption={caption}
        detail={detail}
      />
    )
  }

  //------
  // Creation

  function renderCreateChoice() {
    if (!stateManager.allowCreate) { return null }

    const createPlaceholderFn = props.createPlaceholder ?? defaultCreatePlaceholder()
    const name        = cleanTextValue(query)
    const selected    = selectedIndex === choices.length
    const placeholder = isFunction(createPlaceholderFn) ? createPlaceholderFn(name) : createPlaceholderFn

    return (
      <Tappable classNames={[$.choiceButton, {selected}]} onTap={createNew}>
        <ThemeProvider overrides={{fg: {normal: colors.semantic.positive}}}>
          <ListItem
            image='plus'
            caption={placeholder}
          />
        </ThemeProvider>
      </Tappable>
    )
  }

  //------
  // Placeholders

  const defaultNoResultsPlaceholder = (): {title: string, detail: string} => {
    const queryLength = (query ?? '').length
    if (queryLength < minimumSearchQueryLength) {
      return t('start_typing')
    }

    return t('no_results')
  }

  const defaultCreatePlaceholder = (): (name: string | null) => string => {
    return name => {
      if (name == null) {
        return t('create')
      } else {
        return t('create_named', {name})
      }
    }
  }

  //------
  // Callbacks

  const blurTimeout = React.useRef<number | null>(null)

  const onSearchChange = React.useCallback((event: React.ChangeEvent<any>) => {
    stateManager.setSearchQuery(event.target.value)
  }, [stateManager])

  const requestClose = React.useCallback(() => {
    searchInputRef.current?.blur()
  }, [])

  React.useEffect(() => {
    stateManager.addSelectListener(() => {
      searchInputRef.current?.blur()
    })
  }, [stateManager])

  //------
  // Focus / blur

  const handleSearchFocus = React.useCallback(() => {
    if (blurTimeout.current != null) {
      window.clearTimeout(blurTimeout.current)
    }
  }, [])

  const handleSearchClick = React.useCallback(() => {
    if (blurTimeout.current != null) {
      window.clearTimeout(blurTimeout.current)
    }

    if (!open) {
      setOpen(true)
      stateManager.performSearch()
    }
  }, [open, stateManager])

  const handleResultsMouseDown = React.useCallback(() => {
    // Immediately re-focus the search.
    window.setTimeout(() => {
      searchInputRef.current?.focus()
    }, 0)
  }, [])

  const handleSearchBlur = React.useCallback(() => {
    // Build in a delay to detect a mousedown on the popup.
    if (blurTimeout.current != null) {
      window.clearTimeout(blurTimeout.current)
    }

    blurTimeout.current = window.setTimeout(() => {
      blurTimeout.current = null
      setOpen(false)
    }, 0)
  }, [])

  const prevValue = usePrevious(value)
  React.useEffect(() => {
    // Refocus after clear.
    if (prevValue != null && value == null) {
      searchInputRef.current?.focus()
    }
  }, [prevValue, value])

  //------
  // Selection

  const selectChoice = React.useCallback((choice: C) => {
    stateManager.selectChoice(choice)
  }, [stateManager])

  const createNew = React.useCallback(async () => {
    setOpen(false)
    await stateManager.createNew()
  }, [stateManager])

  const clear = React.useCallback(() => {
    stateManager.clear()
  }, [stateManager])

  //------
  // Keyboard navigation

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<any>) => {
    if (!open) {
      setOpen(true)
      stateManager.performSearch()
      return
    }

    let handled: boolean = true
    switch (event.key) {
      case 'ArrowDown':
        stateManager.moveDown()
        break
      case 'ArrowUp':
        stateManager.moveUp()
        break
      case 'Enter':
        stateManager.commit()
        if (multi) {
          setImmediate(() => {
            searchInputRef.current?.focus()
          })
        }
        break
      case 'Escape':
        searchInputRef.current?.blur()
        break
      default:
        handled = false
    }

    if (handled) {
      event.preventDefault()
    }
  }, [multi, open, stateManager])

  return render()

}

const AutoCompleteField = observer('AutoCompleteField', _AutoCompleteField) as typeof _AutoCompleteField
export default AutoCompleteField

const choiceButtonHeight = 48
const minResultsHeight   = 160
const idealPopupWidth    = 360

export const searchMinWidth = {
  normal: 160,
  small:  80,
}

const useStyles = createUseStyles(theme => ({
  AutoCompleteField: {
    position: 'relative',

    '&:not(.hasSingleValue)': {
      ...presets.field(theme),
    },
    '&.invalid': {
      ...presets.invalidField(theme),
    },

    '&': {
      minHeight: presets.fieldHeight.normal,
      '& $search, & $singleValueContent, & $multiValue': {
        minHeight: presets.fieldHeight.normal - presets.fieldPadding.top - presets.fieldPadding.bottom,
      },
      '& $searchInput': {
        height: presets.fieldHeight.normal - presets.fieldPadding.top - presets.fieldPadding.bottom,
      },
    },
    '&.small': {
      minHeight: presets.fieldHeight.small,
      '& $search, & $singleValueContent, & $multiValue': {
        minHeight: presets.fieldHeight.small - presets.fieldPadding.top - presets.fieldPadding.bottom,
      },
      '& $searchInput': {
        height: presets.fieldHeight.small - presets.fieldPadding.top - presets.fieldPadding.bottom,
      },
    },

    '&.disabled': {
      opacity: 0.6,
    },

    height:    'auto',
    padding:   0,
  },

  singleValue: {
    '&:not(.normal)': {
      boxShadow: [0, 0, 0, 1, theme.semantic.secondary.alpha(0.2)],
    },
  },

  singleValueContent: {
    cursor:  'default',
    padding: presets.fieldPadding,
  },

  multiValues: {
    ...layout.flex.row,
    flexWrap:    'wrap',
    marginRight:  -layout.padding.inline.s,
    marginBottom: layout.padding.inline.s,
  },

  multiValue: {
    marginRight:  layout.padding.inline.s,
    marginBottom: -layout.padding.inline.s,
  },

  search: {},

  multiSearch: {
    marginBottom: -layout.padding.inline.s,

    flex: [1, 0, `${searchMinWidth.normal}px`],
    '&.small': {
      flex: [1, 0, `${searchMinWidth.small}px`],
    },
  },

  popup: {
    minWidth:   idealPopupWidth,
    marginLeft: -presets.fieldPadding.left,
  },

  searchInput: {
    flex: [1, 0, 0],
    ...presets.clearInput(theme),

    padding:    0,
    background: 'none',
    border:     'none',
    font:       'inherit',
    color:      'inherit',
    width:      '100%',

    '-webkit-appearance': 'unset',

    '&:focus': {
      outline:   'none',
      boxShadow: 'none',
    },
  },

  dropDownArrow: {
    marginLeft: presets.fieldPadding.horizontal,
  },

  noResultsPlaceholder: {
    minHeight: choiceButtonHeight,
    maxWidth:  idealPopupWidth,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },

  emptyPlaceholder: {
    padding: presets.fieldPadding,
  },

  sectionHeader: {
    padding:   [layout.padding.inline.m, layout.padding.inline.l],
  },

  section: {
    '&:not(:first-child)': {
      borderTop:  [1, 'solid', theme.fg.normal.alpha(0.05)],
      paddingTop: layout.padding.inline.m,
    },
    '&:first-child > :first-child': {
      borderTopLeftRadius:  layout.radius.s,
      borderTopRightRadius: layout.radius.s,
    },
    '&:last-child > :last-child': {
      borderBottomLeftRadius:  layout.radius.s,
      borderBottomRightRadius: layout.radius.s,
    },
  },

  choiceButton: {
    ...layout.flex.column,
    justifyContent: 'center',
    minHeight:      choiceButtonHeight,

    '& > *': {
      flexGrow: 1,
    },

    '&:hover, &.selected': {
      background: theme.semantic.primary,
      ...colors.overrideForeground(theme.guide.colors.contrast(theme.semantic.primary)),
    },
    '&:focus': {
      outline: 'none',
    },
  },
}))