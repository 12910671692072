import Color from 'color'
import { Theme } from './Theme'

export interface Overrides {
  x?:      number
  y?:      number
  radius?: number
  offset?: number
  color?:  Color
}

export interface Options {
  flip?:  boolean
  inset?: boolean
}

export function shadowCreator(base: Overrides, options: Options = {}) {
  return (overrides: Overrides = {}) => {
    const {
      x      = 0,
      y      = 0,
      radius = 0,
      offset = 0,
      color  = shadowColor,
    } = {...base, ...overrides}

    const shadow: any[] = [
      x,
      options.flip ? -y : y,
      radius,
      offset,
      color,
    ]

    if (options.inset) {
      shadow.unshift('inset')
    }
    return shadow
  }
}

export const shadowColor = new Color('black').alpha(0.3)

export const depth = ((depth: number, overrides: Overrides = {}, options: Options = {}) => {
  if (depth < 0) {
    // Flipped shadow.
    return shadowCreator({y: -depth, radius: -depth * 2, ...overrides}, {...options, inset: true})()
  } else if (depth > 5) {
    // Glow shadow (no y offset and lower opacity)
    return shadowCreator({radius: depth * 2, color: shadowColor.alpha(0.1), ...overrides}, {...options, inset: false})()
  } else {
    // Drop shadow
    return shadowCreator({y: depth, radius: depth * 2, ...overrides}, {...options, inset: false})()
  }
})

export const header = depth(2)

export const focus = {
  subtle:   (theme: Theme) => ['inset', 0, 0, 2, 1, theme.guide.colors.semantic.primary.alpha(0.5)],
  bold:     (theme: Theme) => [0, 0, 0, 2, theme.guide.colors.semantic.primary.alpha(0.5)],
  readonly: (theme: Theme) => [0, 0, 0, 2, theme.fg.dim],
}

export const selected = (theme: Theme) => [0, 0, 0, 3, theme.semantic.primary]
export const popup = depth(2)