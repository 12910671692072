import React from 'react'
import { useTranslation } from 'react-i18next'
import { stringHash } from 'ytil'
import { ChatBackend } from '~/stores/chat'
import { Avatar } from '~/ui/app/media'
import { memo, observer } from '~/ui/component'
import { Chip, HBox, Label, List, ListItem, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from './ChatContext'

const ChatList = observer('ChatList', () => {

  const {service} = useChat()

  const idExtractor = React.useCallback(
    (backend: ChatBackend) => backend.descriptor.uri,
    [],
  )

  const renderItem = React.useCallback((backend: ChatBackend) => (
    <ChatListItem
      backend={backend}
    />
  ), [])

  const fetchMoreChats = React.useCallback(() => {
    service.privateChatsEndpoint.fetchMore()
  }, [service.privateChatsEndpoint])

  //------
  // Rendering

  function render() {
    return (
      <List
        data={service.chats}
        keyExtractor={idExtractor}
        renderItem={renderItem}
        onEndReached={fetchMoreChats}
        scrollable
      />
    )
  }

  return render()

})

interface ChatListItemProps {
  backend: ChatBackend
}

const ChatListItem = memo('ChatListItem', (props: ChatListItemProps) => {

  const {backend} = props

  const {service} = useChat()
  const {descriptor, mostRecentMessage, senders, typingNames, unreadCount} = backend

  const [t] = useTranslation('chat')

  const {colors} = useStyling()

  const goToChat = React.useCallback(() => {
    service.switchToChat(descriptor.uri)
  }, [descriptor.uri, service])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        image={renderAvatar()}
        caption={renderCaption()}
        detail={renderDetail()}
        onTap={goToChat}
        accessory={renderUnreadCount()}
        contentClassNames={$.ChatItem}
      />
    )
  }

  function renderAvatar() {
    return (
      <Avatar
        source={descriptor.image}
        firstName={descriptor.name}
        size={avatarSize}
      />
    )
  }

  function renderCaption() {
    return (
      <Label children={descriptor.name} />
    )
  }

  function renderDetail() {
    if (typingNames.length > 0) {
      return renderTyping()
    } else {
      return renderMostRecentMessage()
    }
  }

  function renderTyping() {
    const names = typingNames
    const count = names.length

    return (
      <Label small dim italic>
        {t('typing', {names, count})}
      </Label>
    )
  }

  function renderMostRecentMessage() {
    if (mostRecentMessage == null) { return null }

    const sender = senders.get(mostRecentMessage.from)
    if (sender == null) { return null }

    const mine   = sender.id === service.sender.id
    const text   = mostRecentMessage.notificationText(mine ? 'sent' : 'received', sender)

    const colorIndex    = stringHash(sender?.id ?? '')
    const emphasisColor = sender == null ? undefined : colors.palette('default', colorIndex)

    return (
      <HBox gap={layout.padding.inline.s}>
        {!mine && sender?.photoURL != null && (
          <Avatar
            {...sender}
            source={sender.photoURL}
            size={senderAvatarSize}
          />
        )}
        <VBox flex>
          <Label dim small markup truncate flex='both' emphasisColor={emphasisColor}>
            {text}
          </Label>
        </VBox>
      </HBox>
    )
  }

  function renderUnreadCount() {
    if (unreadCount === 0) { return null }

    return (
      <Chip small>
        {unreadCount}
      </Chip>
    )
  }

  return render()

})


export default ChatList

export const avatarSize = {
  width:  55,
  height: 55,
}

export const senderAvatarSize = {
  width:  16,
  height: 16,
}

export const itemPaddingVertical = layout.padding.inline.m

const useStyles = createUseStyles({
  ChatItem: {
    paddingTop:    itemPaddingVertical,
    paddingBottom: itemPaddingVertical,
    '&:first-child': {paddingTop: itemPaddingVertical},
    '&:last-child':  {paddingBottom: itemPaddingVertical},
  },
})