import { action, makeObservable, observable } from 'mobx'
import { ClientTab, Project, ProjectFeatures } from '~/models'
import { ResourceFormModel } from '~/ui/resources/form'

export default class ProjectSettingsFormModel extends ResourceFormModel<Project> {

  constructor(
    public readonly project: Project,
  ) {
    super(Project, project)
    this.appFeatures = {...project.appFeatures}
    this.clientTabs  = [...project.clientTabs]

    makeObservable(this)
  }

  @observable
  public appFeatures: ProjectFeatures

  @observable
  public clientTabs: ClientTab[]

  @action
  private getAppFeatureEnabled(name: string) {
    return this.appFeatures[name]?.enabled ?? false
  }

  @action
  private setAppFeatureEnabled(name: string, enabled: boolean) {
    if (enabled) {
      this.appFeatures[name] = {enabled: true, options: {}}
    } else {
      delete this.appFeatures[name]
    }
  }

  public override assign(updates: AnyObject) {
    for (const [name, value] of Object.entries(updates)) {
      if (/^appFeatures\.(.+)\.enabled$/.test(name)) {
        this.setAppFeatureEnabled(RegExp.$1, value)
      } else {
        super.assign({[name]: value})
      }
    }
  }

  public override getValue(name: string) {
    if (/^appFeatures\.(.+)\.enabled$/.test(name)) {
      return this.getAppFeatureEnabled(RegExp.$1)
    } else {
      return super.getValue(name as any)
    }
  }

  //------
  // Submit

  protected override buildData() {
    return {
      ...super.buildData(),
      appFeatures: this.appFeatures,
      clientTabs:  this.clientTabs,
      homeScreen:  {
        ...this.project.homeScreen,
        ...this.getValue('homeScreen'),
        welcome: {
          ...this.project.homeScreen.welcome,
          ...this.getValue('homeScreen.welcome'),
        },
      },
    }
  }

}