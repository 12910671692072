import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { Channel, Participant } from '~/models'
import { chatStore } from '~/stores'
import { ChatContainer } from '~/ui/app/chat'
import { observer } from '~/ui/component'
import { Dimple, Empty, HBox, Panel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ChannelHeader from './ChannelHeader'

export interface Props {
  channel: Channel
}

const ChannelBody = observer('ChannelBody', (props: Props) => {

  const {channel} = props
  const [participant, setParticipant] = React.useState<Participant | null>(null)
  const {t} = useResourceTranslation()


  const chat = React.useMemo(() => {
    if (participant == null) { return null }
    return chatStore.chatService(participant.id, `channel:${channel.id}`)
  }, [channel.id, participant])

  useOnDemandService(chat)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex>
        {renderHeader()}
        <Dimple horizontal/>
        {participant == null ? (
          renderPrompt()
        ) : (
          renderBody()
        )}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <ChannelHeader
        channel={channel}
        participantID={participant?.id ?? null}
        requestParticipant={setParticipant}
      />
    )
  }

  function renderPrompt() {
    return (
      <Empty
        flex
        {...t('prompt')}
      />
    )
  }

  function renderBody() {
    if (chat == null) { return null }

    return (
      <HBox classNames={$.body} flex align='stretch' justify='center'>
        <VBox classNames={$.preview}>
          <Panel flex>
            <ChatContainer
              service={chat}
              header={false}
              emptyChannel={{
                title: t('chat:no_messages.title'),
                detail: t(`chat:no_messages.detail.${channel.isOpen ? 'open' : 'closed'}`),
              }}
            />
          </Panel>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ChannelBody

const useStyles = createUseStyles(theme => ({
  body: {
    ...layout.responsiveProp({padding: layout.padding.m}),
    paddingBottom: 0,
  },

  preview: {
    flex:         [0, 1, '480px'],
    background:   theme.colors.bg.semi,
    boxShadow:    shadows.depth(2),
    borderRadius: layout.radius.m,
  },
}))