import React from 'react'
import { observer } from '~/ui/component'
import { Center, Scroller, SwitchField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import { RichTextField } from '../../fields'
import ProjectSettingsFormModel from './ProjectSettingsFormModel'

export interface Props {

}

const HomeScreenSettings = observer('HomeScreenSettings', (props: Props) => {

  const {model: formModel, submit} = useForm<ProjectSettingsFormModel>()

  //------
  // Rendering

  function render() {
    return (
      <Scroller>
        <Center>
          <VBox width={520} gap={layout.padding.xs}>
            {renderMessage()}
            <FormField name='homeScreen.search'>
              {bind => <SwitchField {...bind}/>}
            </FormField>
            <FormField name='homeScreen.infoMenu'>
              {bind => <SwitchField {...bind}/>}
            </FormField>
            <FormField name='homeScreen.recent'>
              {bind => <SwitchField {...bind}/>}
            </FormField>
            <FormField name='homeScreen.agenda'>
              {bind => <SwitchField {...bind}/>}
            </FormField>
          </VBox>
        </Center>
      </Scroller>
    )
  }

  function renderMessage() {
    const body = formModel.getValue('homeScreen.welcome.message')

    return (
      <>
        <FormField name='homeScreen.welcome.enabled'>
          {bind => <SwitchField {...bind} />}
        </FormField>
        {formModel.getValue('homeScreen.welcome.enabled') && (
          <FormField name='homeScreen.welcome.message' caption={false}>
            {bind => (
              <RichTextField
                allowedNodes={['em', 'strong', 'variable']}
                localized
                commitOnBlur
                onCommit={() => submit()}
                {...bind}
                value={body ?? {}}
              />
            )}
          </FormField>
        )}
      </>
    )
  }

  return render()

})

export default HomeScreenSettings