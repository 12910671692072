import './serializers'
import { isFunction } from 'lodash'
import { DateTime } from 'luxon'
import YModel, { ModelSerialized, serialize } from 'ymodel'
import { LocalizedString } from '~/models'
import { SVGName } from '~/ui/components/SVG'
import { Module } from './Module'
import { importable } from './importing'
import { Ref, ResourceConfig, ResourceInfo, ResourceReflectionContext } from './types'

export abstract class Model extends YModel {

  public module!: Ref<Module>

  @importable()
  public id!: string

  @serialize(DateTime)
  public createdAt!: DateTime

  @serialize(DateTime)
  public updatedAt!: DateTime

  //------
  // Resources

  public static reflectionContext = ResourceReflectionContext.empty()

  public get $ModelClass() {
    return this.constructor as ModelClass<this>
  }

  public static get $ModelClass() {
    return this as unknown as ModelClass<any>
  }

  public static get resourceType(): string {
    const config = RESOURCES.get(this.$ModelClass)
    if (config == null) {
      throw new Error(`Model ${this.name} not registered`)
    }

    return config.type
  }

  public static get scopedToModule(): boolean {
    const config = RESOURCES.get(this.$ModelClass)
    if (config == null) {
      throw new Error(`Model ${this.name} not registered`)
    }

    return config.scopedToModule
  }

  public static get include(): string[] {
    const config = RESOURCES.get(this.$ModelClass)
    if (config == null) {
      throw new Error(`Model ${this.name} not registered`)
    }

    return config.include
  }

  public static get $icon(): SVGName {
    const icon = reflect<SVGName>(this.$ModelClass, 'icon')
    return isFunction(icon) ? icon() : icon
  }

  public get $icon() {
    return reflect<SVGName>(this, 'icon')
  }

  public get $caption() {
    const caption = reflect<string>(this, 'caption')
    return LocalizedString.translate(caption)
  }

  public get $details() {
    return reflect<React.ReactNode>(this, 'details')
  }

  public get $hasDetail() {
    return reflect<boolean>(this, 'hasDetail', true)
  }

  //-------
  // Serialization helpers

  protected static beforeDeserialize(serialized: ModelSerialized) {
    return serialized
  }

}

//------
// Resources

const RESOURCES = new Map<ModelClass<any>, ResourceInfo<any>>()

export function ResourceModels() {
  return Array.from(RESOURCES.keys())
}

export function ModelOfType<M extends Model>(resourceType: string): ModelClass<M> {
  for (const [Model, config] of RESOURCES) {
    if (resourceType === config.type) {
      return Model
    }
  }

  throw new Error(`No model class found for resource type \`${resourceType}\``)
}

export function ModelWithName<M extends Model>(name: string): ModelClass<M> {
  for (const [Model] of RESOURCES) {
    if (name === Model.name) {
      return Model
    }
  }

  throw new Error(`No model class found with name \`${name}\``)
}

export function reflect<U>(modelOrClass: Model | ModelClass<any>, which: keyof ResourceConfig<any>, defaultValue?: U): U {
  const ModelClass = ((modelOrClass as any).prototype instanceof Model ? modelOrClass : modelOrClass.constructor) as ModelClass<any>
  const model      = (modelOrClass as any).prototype instanceof Model ? null : modelOrClass
  const info       = RESOURCES.get(ModelClass)

  let item: any = info?.[which]
  if (item === undefined && defaultValue === undefined) {
    throw new Error(`Cannot retrieve \`${which}\` of ${ModelClass.name}`)
  }
  if (model != null && isFunction(item)) {
    item = item(model, Model.reflectionContext)
  }

  return (item ?? defaultValue) as unknown as U
}

export function resource<M extends Model>(type: string, config: ResourceConfig<M>) {
  return (target: Constructor<M>) => {
    RESOURCES.set(target as ModelClass<M>, {
      type,
      details:   () => null,
      hasDetail: () => true,

      include:        [],
      scopedToModule: true,
      copyAction:     'reassign',
      appLink:        null,

      ...config,
    })
  }
}

export interface ModelClass<M extends Model> {
  new(serialized: ModelSerialized): M

  resourceType:   string
  scopedToModule: boolean
  include:        string[]

  $icon:          SVGName

  deserialize(raw: ModelSerialized): M
  serializePartial(model: Partial<M>): ModelSerialized
}