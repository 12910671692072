import { action, computed, IReactionDisposer, makeObservable, observable } from 'mobx'
import Semaphore from 'semaphore'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { sparse } from 'ytil'
import {
  FeedbackMediaType,
  feedbackMediaTypeForMimeType,
  Participant,
  PendingMessageResource,
  PendingMessageTemplate,
  Sender,
} from '~/models'
import { dataStore } from '~/stores'
import { objectID } from '../../lib/ytil/src/objectID'
import ChatBackend from './ChatBackend'
import {
  ChatDescriptor,
  ChatState,
  ChatUri,
  IncomingMessageListener,
  MessagePayload,
  StatusPayload,
  TypingPayload,
} from './types'

export default class ChatService extends OnDemandService {

  constructor(
    socket: Socket,
    public readonly participantID: string,
    public readonly uri: ChatUri,
  ) {
    super(socket)
    makeObservable(this)
  }

  private disposers: IReactionDisposer[] = []

  public dispose() {
    this.disposers.forEach(it => it())
  }

  private chatStarted = new Semaphore()

  // #region Lifecycle

  public async start() {
    await super.startWithEvent('chat:start', {
      participantID: this.participantID,
      uri: this.uri,
    })
  }

  @action
  protected onStarted = (response: StartSuccess<InitialData>) => {
    this.socket.prefix = `chat:${this.uid}:`
    this.socket.addEventListener('message', this.onIncomingMessage)
    this.socket.addEventListener('status', this.onStatus)
    this.socket.addEventListener('typing:start', this.onStartTyping)
    this.socket.addEventListener('typing:stop', this.onStopTyping)

    this._descriptor = response.data.chat
    this._descriptor.createdAt = new Date(this._descriptor.createdAt)

    this.chatStarted.signal()
  }

  public onStop() {
    this.chatStarted.reset()
  }

  // #endregion

  // #region State

  @observable
  private _state: ChatState = ChatState.empty()
  public get state() { return this._state }

  @action
  protected mergeState(update: ChatState) {
    Object.assign(this._state, {
      unreadCounts:     {...this._state.unreadCounts, ...update.unreadCounts},
      totalUnreadCount: update.totalUnreadCount,
    })
  }

  // #endregion

  // #region Chat switching & backends

  @observable
  private _descriptor: ChatDescriptor | null = null
  public get descriptor() { return this._descriptor }

  @computed
  public get currentChat() {
    const {descriptor} = this
    if (descriptor == null) { return null }

    const backend = new ChatBackend(this, descriptor)
    backend.fetchNewMessages()
    return backend
  }

  // #endregion

  // #region Senders

  @computed
  public get sender(): Sender {
    const participant = dataStore.get(Participant, this.participantID)
    if (participant == null) {
      throw new Error(`Participant ${this.participantID} not found`)
    }

    return {
      type:      'participant',
      id:        participant.id,
      firstName: participant.firstName,
      lastName:  participant.lastName,
      photoURL:  participant.photoURL,
      headline:  participant.headline,
    }
  }

  public async fetchSenders(offset: number | null | undefined = 0, limit: number | null | undefined = 20) {
    const response = await this.socket.fetch('senders', this.uri, {
      limit:  limit,
      offset: offset,
    })

    return response
  }

  // #endregion

  // #region Socket listeners

  @action
  private onIncomingMessage = (payload: MessagePayload) => {
    if (payload.chat !== this.uri) { return }

    const {currentChat: chat} = this
    chat?.handleIncomingMessage(payload).then(messages => {
      for (const message of messages) {
        const sender = chat.senders.get(message.from)
        if (sender == null) { continue }

        for (const listener of this.incomingMessageListeners) {
          listener({message, sender, chat: chat.descriptor})
        }
      }
    })
  }

  @action
  private onStatus = (payload: StatusPayload) => {
    for (const update of payload.statuses) {
      if (update.chat !== this.uri) { return }
      this.currentChat?.updateMessageStatus(update)
    }
  }

  private incomingMessageListeners = new Set<IncomingMessageListener>()

  public addIncomingMessageListener(listener: IncomingMessageListener) {
    this.incomingMessageListeners.add(listener)
    return () => { this.incomingMessageListeners.delete(listener) }
  }

  @action
  private onStartTyping = (payload: TypingPayload) => {
    if (payload.chat !== this.uri) { return }
    this.currentChat?.onStartTyping(payload.sender)
  }

  @action
  private onStopTyping = (payload: TypingPayload) => {
    if (payload.chat !== this.uri) { return }
    this.currentChat?.onStopTyping(payload.sender.id)
  }

  // #endregion

  // #region Media messages

  public async buildPendingMediaMessageTemplate(file: File, allowedFeedbackMediaTypes: FeedbackMediaType[]): Promise<Partial<PendingMessageTemplate> | null> {
    const mediaType = feedbackMediaTypeForMimeType(file.type)
    if (mediaType == null || !allowedFeedbackMediaTypes.includes(mediaType)) {
      return null
    }

    const resource: PendingMessageResource = {
      filename: file.name,
      mimeType: file.type,
      binary:   file,
    }

    if (mediaType === 'image') {
      return {type: 'image', image: resource}
    } else {
      return {type: 'video', video: resource}
    }
  }

  public readMediaAsBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = () => reject(reader.error)
      reader.onload  = () => {
        const dataURL: string = reader.result as string
        const base64 = dataURL.replace(/^data:.*;base64,/, '')
        resolve(base64)
      }

      reader.readAsDataURL(blob)
    })
  }

  // #endregion

  // #region Stub interface for chat

  /*
   * ----
   * Contrary to how the chat service works in the app, the mission control chat service only works with a single chat.
   * For this reason, we stub out things related to multiple chats and chat switching. Because we only ever have one chat,
   * the UI will never show a chat list.
   * ----
   */


  @computed
  public get chats() {
    return sparse([this.currentChat])
  }

  public switchToChat(uri: ChatUri | null) {
    /* noop */
  }

  public get totalUnreadCount() {
    return 0
  }

  public privateChatsEndpoint = {
    fetch() {},
    fetchMore() {},
  }

  // #endregion

}

export interface InitialData {
  chat: ChatDescriptor
  state: ChatState
}