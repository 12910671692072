import { action, computed, makeObservable, observable, reaction } from 'mobx'
import socket from 'socket.io-react'
import { authenticationStore } from '~/stores'
import { OperatorService } from './operator'
import { register } from './support'

export class OperatorStore {

  constructor() {
    makeObservable(this)
  }

  public init() {
    reaction(() => authenticationStore.linkedParticipantIDs, ids => {
      if (ids.length === 1) {
        this.setSelfID(ids[0])
      } else if (ids.length === 0) {
        this.setSelfID(null)
      } else if (this.selfID != null && !ids.includes(this.selfID)) {
        this.setSelfID(null)
      }
    }, {fireImmediately: true})
  }

  @observable
  private _selfID: string | null = null
  public get selfID() { return this._selfID }

  @action
  public setSelfID(selfID: string | null) {
    if (selfID === this._selfID) { return }
    this._selfID = selfID
  }

  @observable
  public service: OperatorService | null = null

  @computed
  public get starting() {
    return this.service?.starting ?? true
  }

  @computed
  public get started() {
    return this.service?.started ?? false
  }

  @computed
  public get startError() {
    return this.service?.startError ?? null
  }

  private persistedParticipants: Array<[string, string]> = []

  @action
  public startService() {
    const service = new OperatorService(socket, this.persistedParticipants)
    service.start().then(action(() => {
      this.service = service
    }))

    return () => {
      service.stop()

      if (service === this.service) {
        this.service = null
      }
    }
  }

  //------
  // Persistence

  public persistenceKey = 'operator'

  public persist() {
    const participants = this.service != null
      ? Array.from(this.service.participants)
      : this.persistedParticipants

    return {
      participants: participants ?? [],
    }
  }

  public rehydrate(raw: AnyObject) {
    if (raw.participants != null) {
      this.persistedParticipants = raw.participants
    }
  }

}

const operatorStore = register(new OperatorStore())
export default operatorStore