import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { SVGName } from '~/ui/components/SVG'
import { LatLong } from '../ui/app/google-maps/types'
import { ClientApp } from './ClientApp'
import { CustomImage, SVGImage } from './CustomImage'
import { Link } from './Link'
import { Model, resource } from './Model'
import { Organisation } from './Organisation'
import { Page } from './Page'
import { Language, LocalizedString } from './Translations'
import { Ref } from './types'

@resource<Project>('projects', {
  icon:    'logo',
  caption: project => project.name,
  scopedToModule: false,
})
export class Project extends Model {

  public organisation!: Ref<Organisation>

  public name!: string
  public code!: string
  public logo!: CustomImage | null

  public participantCount!: number
  public archived!: boolean

  public linkedProjects!: Ref<Project>[]

  @serialize(DateTime)
  public startDate!: DateTime
  @serialize(DateTime)
  public endDate!: DateTime | null

  public app!: Ref<ClientApp>

  public clientTabs!:  ClientTab[]
  public appFeatures!: ProjectFeatures
  public features!:    ProjectFeatures
  public web!:         WebConfig
  public homeScreen!:  HomeScreenConfig

  public timeZone!: string
  public location!: ProjectLocation | null

  public identityGroupTags!: string[]
  public languages!: string[]

  public rootPage!: Ref<Page> | null

  public extensions!: ProjectExtension[]

}

export interface ProjectLocation {
  center: LatLong
  radius: number
  name:   string | null
}

export interface Webhook {
  token:  string
  events: WebhookEvent[]
}

export interface WebhookEvent {
  type:       string
  individual: boolean
}

export interface ProjectExtension {
  name:    string
  enabled: boolean
  config:  AnyObject
  valid:   boolean
}

export type ProjectFeatures = Record<string, ProjectFeature>

export interface ProjectFeature {
  enabled: boolean
  options: AnyObject
}

export interface ClientTab {
  link:    Link
  caption: Record<Language, string>
  icon:    {
    type:  'named'
    value: SVGName
  } | {
    type: 'custom'
    value: SVGImage
  }
}

export const ClientTabIconType: {
  all: ClientTabIconType[]
} = {
  all: ['custom', 'named'],
}

export type ClientTabIconType = 'custom' | 'named'

export interface ClientTabTemplate {
  href:      string
  default:   boolean
  dependsOn: string[]
  platforms: string[]
  caption:   Record<Language, string>
  icon:      SVGName
}

export interface WebConfig {
  layout: WebLayout
}

export type WebLayout = 'live' | 'chat' | 'full'

export interface HomeScreenConfig {
  welcome: {
    enabled: boolean
    message: LocalizedString
  }
  search:   boolean
  infoMenu: boolean
  recent:   boolean
  agenda:   boolean
}