import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { chatStore, operatorStore } from '~/stores'
import ChatContainer from '~/ui/app/chat/ChatContainer'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'

export interface Props {
  participantID: string
}

const OperatorChat = observer('OperatorChat', (props: Props) => {

  const {participantID} = props
  const selfID = operatorStore.selfID ?? null

  //------
  // Services

  const chat = React.useMemo(() => {
    if (selfID == null) { return null }
    return chatStore.chatService(selfID, `private:${participantID}`)
  }, [participantID, selfID])

  useOnDemandService(chat)

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        {renderChat()}
      </VBox>
    )
  }

  function renderChat() {
    if (chat == null) { return null }

    return (
      <ChatContainer
        service={chat}
        header={false}
      />
    )
  }

  return render()

})

export default OperatorChat