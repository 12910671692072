import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Center, ErrorDisplay, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props {
  status:    number
  title?:    string
  detail?:   string
  children?: React.ReactNode
}

const ErrorScreen = memo('PageNotFoundScreen', (props: Props) => {

  const {status, children} = props

  const [t] = useTranslation('errors')

  const {title, detail} = React.useMemo((): Pick<Props, 'title' | 'detail'> => {
    if (props.title != null) {
      return {title: props.title, detail: props.detail}
    } else {
      return t([`${status === 404 ? 'page_not_found' : status}`, 'unknown'])
    }
  }, [t, props.title, props.detail, status])

  function render() {
    return (
      <Center flex>
        <VBox gap={layout.padding.m} align='left'>
          <ErrorDisplay
            status={status}
            title={title}
            detail={detail}
            children={children}
          />
        </VBox>
      </Center>
    )
  }

  return render()

})

export default ErrorScreen